import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rem, em } from 'polished';
import { graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { Element, scroller } from 'react-scroll';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

import SEO from '../components/YoastSEO';
import Layout from '../components/Layout';
import Row from '../components/Row';
import Col from '../components/Col';

import { breakpoint, colors } from '../theme';

const Container = styled.div`
  max-width: ${(props) => props.theme.grid.maxWidth};
  padding: ${rem(50)} auto 0;
  /* padding-bottom: ${rem(110)}; */
  text-align: left;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100vh;
`;

const Tagline = styled.h1`
  font-size: ${rem(30)};
  line-height: 1.14;

  ${breakpoint('medium')} {
    font-size: ${rem(36)};
  }

  ${breakpoint('mediumlarge')} {
    font-size: ${rem(42)};
  }

  ${breakpoint('large')} {
    font-size: ${rem(62)};
  }

  ${breakpoint('xlarge')} {
    font-size: ${rem(68)};
  }
`;

const Text = styled.p`
  margin: 0;
  font-size: ${rem(14)};

  ${breakpoint('medium')} {
    font-size: ${rem(16)};
  }

  ${breakpoint('large')} {
    font-size: ${rem(18)};
  }

  ${breakpoint('large')} {
    font-size: ${rem(20)};
  }
`;

const ScrollToButton = styled.button`
  background: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  text-align: center;
  border: 2px solid #000;
  border-radius: 999px;
  height: ${em(32)};
  width: ${em(32)};
  font-size: ${rem(15)};
  color: #000;
  margin-top: ${rem(40)};

  &:hover {
    color: #000;
  }
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: 1em;
  color: inherit;
`;

function NotFound({ data }) {
  const { t } = useTranslation();

  function scrollToBottom() {
    scroller.scrollTo('pageBottom', {
      duration: 1000,
      delay: 100,
      smooth: true,
      offset: -70,
    });
  }

  const {
    site: {
      siteMetadata: { title: fallbackTitle },
    },
    // wp: {
    //   seo: {
    //     schema: { siteName },
    //     breadcrumbs: { notFoundText: siteTitle },
    //   },
    // },
  } = data;

  return (
    <Layout>
      <SEO
        // title={siteTitle || `404: Not found - ${siteName || fallbackTitle}`}
        title={`${fallbackTitle} - ${t('homeSiteTitle')}`}
      />

      <Container>
        <Row>
          <Col>
            <Tagline>{t('homeTagline')}</Tagline>
            <Text>
              #react #wordpress #react-native #gatsby #javascript #stockholm
              #dalarna #sverige #unitedkingdom
              {/* t('homeText') */}
              <ScrollToButton type="button" onClick={() => scrollToBottom()}>
                <Icon icon={faChevronDown} color={colors.primary} />
              </ScrollToButton>
            </Text>
          </Col>
        </Row>
      </Container>

      <Element name="pageBottom" />
    </Layout>
  );
}

NotFound.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
};

// export const query = graphql`
//   query NotFound {
//     site {
//       siteMetadata {
//         title
//       }
//     }
//     wp {
//       seo {
//         schema {
//           siteName
//         }
//         breadcrumbs {
//           notFoundText
//         }
//       }
//     }
//   }
// `;

export const query = graphql`
  query Home {
    site {
      siteMetadata {
        title
      }
    }
  }
`;

export default NotFound;
